import {
    Box,
    Typography,
    Theme,
    makeStyles,
    createStyles
} from "@material-ui/core"
import Heading from "../../components/Heading";
import NavBar from "../../components/NavBar";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        background: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.shape.borderRadius
        },
    })
);

const NotFoundPage = () => {
    const classes = useStyles();

    return (
        <Box>
            <NavBar />
            <Box mt={8} display='flex' justifyContent='center'>
                <Box
                    p={3} mt={6} maxWidth={640} width='100%'
                    className={classes.background}
                >
                    <Heading>404: Page not found</Heading>
                    <Typography>The requested page could not be found.</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export default NotFoundPage;
