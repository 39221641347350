import {
    Box,
    makeStyles,
    Theme,
    createStyles,
    Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { Instance } from "../../types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.shape.borderRadius
        },
    })
);

type InstanceListItemProps = {
    instance: Instance;
}
const InstanceListItem = ({
    instance
}: InstanceListItemProps) => {
    const classes = useStyles();
    return (
        <Box mb={1}>
            <Link to={`/instances/${instance.instanceId}`}>
                <Box
                    display='flex' alignItems='center'
                    p={2}
                    className={classes.listItem}
                >
                    <Typography variant='subtitle2'>{instance.host}</Typography>
                </Box>
            </Link>
        </Box>
    )
}

export default InstanceListItem;