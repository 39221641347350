import {
    Box,
    CircularProgress,
    createStyles,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        listItem: {
            border: `2px solid ${theme.palette.grey[100]}`,
            borderRadius: theme.shape.borderRadius
        },
    })
);

type CreatingInstanceItemProps = {
    hostName: string
}
const CreatingInstanceItem = ({
    hostName
}: CreatingInstanceItemProps) => {
    const classes = useStyles();

    return (
        <Box
            display='flex' alignItems='center' justifyContent='space-between'
            p={2} mb={1}
            className={classes.listItem}
        >
            <Typography variant='subtitle2'>{hostName}</Typography>
            <Box display='flex' mr={2}>
                <CircularProgress size={24} />
                <Box mr={1} />
                <Typography>Creating...</Typography>
            </Box>
        </Box>
    )
}

export default CreatingInstanceItem;