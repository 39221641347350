export interface Instance {
  name: string;
  instanceId: string;
  host: string;
}

export interface InstanceDetails {
  instanceId: string;
  name: string;
  activeModules: Module[];
  activeSubModules: Record<Module, SubModule[]>;
  enabledSubModules: Record<Module, SubModule[]>;
  instanceLanguage: InstanceLanguage;
  host: string;
  userLimit: number | null;
  roomLimit: number | null;
  adminLimit: number | null;
  groupLimit: number | null;
}

export const InstanceLanguages = ["English", "Svenska"] as const;
export type InstanceLanguage = typeof InstanceLanguages[number];

export type UpdateInstanceDetails = Omit<
  InstanceDetails,
  "activeModules" | "activeSubModules" | "host"
>;

export type AvailableModules = Record<Module, SubModule[]>;

export type Module = "Forum" | "External" | "Course" | "Notification" | "Chat";
export type SubModule =
  | "PrivateForum"
  | "PublicForum"
  | "PrivateCourse"
  | "PublicCourse"
  | "PrivateVideos"
  | "CourseNotifications"
  | "ForumNotifications"
  | "PrivateChat"
  | "PublicChat";
