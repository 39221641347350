import {
    CircularProgress,
    Container,
    Typography,
    makeStyles,
    createStyles,
    Theme,
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      centeredContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(5, 0),
        textAlign: 'center',
      },
    })
  );
  
  const Authenticating = () => {
    const classes = useStyles();
  
    return (
      <Container>
        <main className={classes.centeredContainer}>
          <Typography variant="h6" component="h1" gutterBottom>
            Authenticating...
          </Typography>
          <CircularProgress size={48} />
        </main>
      </Container>
    );
  };
  
  export default Authenticating;
  