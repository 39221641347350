import { SvgIcon, SvgIconProps } from "@material-ui/core";

const ForumIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" >
            <path d="M1.5 3.75V15.75H4.5V19.5705L5.71875 18.5858L9.258 15.75H16.5V3.75H1.5ZM3 5.25H15V14.25H8.742L8.53125 14.4143L6 16.4295V14.25H3V5.25ZM18 6.75V8.25H21V17.25H18V19.4295L15.258 17.25H9.633L7.758 18.75H14.742L19.5 22.5705V18.75H22.5V6.75H18Z" />
        </SvgIcon>
    );
}

export default ForumIcon;