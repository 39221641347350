import { Typography } from "@material-ui/core"

type HeadingProps = {
    children: string
}
const Heading = ({
    children
}: HeadingProps) => {
    return (
        <Typography variant='h5' component='h2' gutterBottom>
            {children}
        </Typography>
    )
}

export default Heading;