import {
    Box,
    Typography,
    TextField,
    createStyles,
    makeStyles,
    Theme
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        limitItem: {
            backgroundColor: theme.palette.grey[200],
            borderRadius: theme.shape.borderRadius,
        },
        formControl: {
            width: 'fit-content'
        }
    })
);

type LimitInputProps = {
    title: string,
    value: number | null,
    unit?: string,
    onChange: (event: any) => void
}
const LimitInput = ({
    title,
    value,
    unit,
    onChange
}: LimitInputProps) => {
    const classes = useStyles();

    return (
        <Box mb={3} p={2} className={classes.limitItem} maxWidth={280}>
            <Box mb={2}>
                <Typography variant='subtitle2'>
                    {title}
                </Typography>
            </Box>
            <Box display='flex' alignItems='center' mb={1}>
                <TextField
                    type={value ? 'number' : 'text'}
                    variant='outlined'
                    value={value ? value : 'Unlimited'}
                    onInput={onChange}
                    style={{ width: 120 }}
                />
                {unit &&
                    <Box ml={1} display='flex' alignItems='center'>
                        <Typography>{unit}</Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default LimitInput;