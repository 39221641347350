import {
    Container,
    Typography,
    makeStyles,
    createStyles,
    Theme,
  } from '@material-ui/core';
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      centeredContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(5, 0),
        textAlign: 'center',
      },
    })
  );
  
  const NotAuthenticated = () => {
    const classes = useStyles();
  
    return (
      <Container>
        <main className={classes.centeredContainer}>
          <Typography variant="h2" component="h1" gutterBottom>
            Access denied
          </Typography>
          <Typography variant="subtitle1" component="p" gutterBottom>
            You are not authenticated.
          </Typography>
        </main>
      </Container>
    );
  };
  
  export default NotAuthenticated;
  