import { useReactOidc } from "@axa-fr/react-oidc-context";
import {
  AppBar,
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    homeLink: {
      backgroundColor: theme.palette.secondary.light,
      borderRadius: theme.shape.borderRadius,
    },
  })
);

const NavBar = () => {
  const { logout } = useReactOidc();
  const classes = useStyles();

  return (
    <AppBar position="fixed" color="default">
      <Toolbar>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Link to="/">
            <Box
              className={classes.homeLink}
              p={1}
              pr={2}
              pl={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box mr={1} display="flex" alignItems="center">
                <img alt="logo" src="/logo.ico" height={32} />
              </Box>
              <Typography variant="body2" color="primary">
                ConductHub Admin
              </Typography>
            </Box>
          </Link>
          <Button onClick={() => logout()}>Log out</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
