const authorityBaseUrl =
window.location.origin.includes('localhost') || window.location.origin.includes('conducthubadmin-dev')
    ? 'https://web-dev.conductonline.devies.se'
    : 'https://web.conductonline.devies.se';

const oidcConfig = {
  client_id: 'conducthubadmin',
  redirect_uri: `${window.location.origin}/authentication/callback`,
  response_type: 'code',
  post_logout_redirect_uri: '',
  scope: 'openid profile email roles api',
  authority: `${authorityBaseUrl}/signin`,
  silent_redirect_uri: `${window.location.origin}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

export default oidcConfig;