export const getModuleName = (moduleDefaultName: string): string => {
    switch (moduleDefaultName) {
        // Public
        case 'PublicForum':
            return 'Public forum';

        case 'PublicLiveStream':
            return 'Public live streams';

        // Private
        case 'PrivateForum':
            return 'Private forum';

        case 'PrivateCourse':
            return 'Private masterclasses';

        case 'PublicCourse':
            return 'Public masterclasses';

        case 'PrivateVideos':
            return 'Private videos';

        case 'PrivateLiveStream':
            return 'Private live streams';

        // Group
        case 'GroupCourse':
            return 'Group masterclasses';

        case 'GroupForum':
            return 'Group forum';

        case 'GroupVideos':
            return 'Group videos';

        case 'GroupLiveStream':
            return 'Group live streams';

        // Notiifcations
        case 'CourseNotifications':
            return 'Masterclass notifications';

        case 'ForumNotifications':
            return 'Forum notifications';

        case 'VideoNotifications':
            return 'Video notifications';

        // Chat
        case 'PrivateChat':
            return 'Chat for connected users';

        case 'PublicChat':
            return 'Chat for all users';

        default:
            return moduleDefaultName;
    }
}
