import axios from "axios";

export const baseApiUrl = `${window.location.origin}/api/`;
// const localApiPort = '5008';

const getApiUrl = () =>
  window.location.origin.includes("localhost")
    ? `http://localhost:5008/api` //`https://conducthubadmin-dev.conductonline.devies.se/api`
    : baseApiUrl;

const httpClient = axios.create({
  baseURL: getApiUrl(),
});

httpClient.interceptors.response.use((response) => response, handleHttpError);

function handleHttpError(error: any) {
  if (error.response?.status === 401 || error.response?.status === 403) {
    // User is not authenticated or not authorized
    // Refresh page to trigger authentication flow
    let url = new URL(window.location.href);
    const refreshNumber = url.searchParams.get("refresh");
    if (refreshNumber === null) {
      url = new URL(window.location.origin);
      url.searchParams.append("refresh", "1");
      window.location.href = url.toString();
    } else if (refreshNumber === "1") {
      url.searchParams.set("refresh", "2");
      window.location.href = url.toString();
    } else {
      //Something is wrong, logout
      if (logout) {
        logout();
      } else {
        localStorage.clear();
      }
    }
    return Promise.reject(error);
  }

  return Promise.reject(error);
}

let logout: Function | null = null;

export const setLogoutFunction = (logoutFunction: Function) => {
  logout = logoutFunction;
};

export const addAccessToken = (accessToken: string) => {
  const addToken = (config: any) => {
    const token = accessToken;
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  };

  httpClient.interceptors.request.use(addToken, (error) => {
    Promise.reject(error);
  });
};

export default httpClient;
