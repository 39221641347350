import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";

type NewInstanceDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  onCreate: (host: string, firstAdminEmail: string) => void;
};
const NewInstanceDialog = ({
  isOpen,
  onClose,
  onCreate,
}: NewInstanceDialogProps) => {
  const [host, setHost] = useState<string>("");
  const [hostError, setHostError] = useState<string | null>(null);
  const [firstAdminEmail, setFirstAdminEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);

  const handleHostChange = (newHost: string) => {
    const hostNameFormat =
      /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
    if (!newHost.match(hostNameFormat)) {
      setHostError("Please enter a valid host name.");
    } else {
      setHostError(null);
    }
    setHost(newHost);
  };

  const handleEmailChange = (newEmail: string) => {
    const emailFormat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!newEmail.match(emailFormat)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError(null);
    }
    setFirstAdminEmail(newEmail);
  };

  const handleCreateNewInstance = () => {
    onCreate(host, firstAdminEmail);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
      <Box mt={1}>
        <DialogTitle>Add new instance</DialogTitle>
      </Box>
      <DialogContent>
        <Box mb={3}>
          <Box mb={1}>
            <Typography variant="subtitle2">Host name</Typography>
          </Box>
          <TextField
            type="text"
            variant="outlined"
            value={host}
            placeholder="Ex: http://conduct.com"
            onInput={(event: any) => handleHostChange(event.target.value)}
            fullWidth
            error={!!hostError && host.length >= 3}
            helperText={
              !!hostError && host.length >= 3
                ? hostError
                : "A host name owned by the customer"
            }
          />
        </Box>

        <Box mb={3}>
          <Box mb={1}>
            <Typography variant="subtitle2">First admin email</Typography>
          </Box>
          <TextField
            type="email"
            variant="outlined"
            value={firstAdminEmail}
            placeholder="Ex: admin.user@conduct.com"
            onInput={(event: any) => handleEmailChange(event.target.value)}
            fullWidth
            error={!!emailError && firstAdminEmail.length >= 5}
            helperText={
              !!emailError && firstAdminEmail.length >= 5
                ? emailError
                : "The email of the first admin user"
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={2} display="flex">
          <Button onClick={onClose}>Cancel</Button>
          <Box mr={1} />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateNewInstance}
            disabled={!host || !firstAdminEmail || !!hostError || !!emailError}
          >
            Create
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default NewInstanceDialog;
