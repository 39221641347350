import { BrowserRouter, Routes, Route } from 'react-router-dom';
import InstanceDetailPage from '../pages/InstanceDetailPage';
import InstanceListPage from '../pages/InstanceListPage';
import NotFoundPage from '../pages/NotFoundPage';

const AppRoutes = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InstanceListPage />} />
        <Route path="instances" element={<InstanceListPage />} />
        <Route path="instances/:instanceId" element={<InstanceDetailPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
