import { Box, Button, CircularProgress } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState, useEffect } from "react";
import { getAllInstanceSettings, postNewInstance } from "../../api/api";
import Heading from "../../components/Heading";
import InstanceListItem from "./InstanceListItem";
import NavBar from "../../components/NavBar";
import { Instance } from "../../types";
import NewInstanceDialog from "./NewInstanceDialog";
import CreatingInstanceItem from "./CreatingInstanceItem";

const InstanceListPage = () => {
  const [instances, setInstances] = useState<Instance[] | null>(null);
  const [isNewInstanceDialogOpen, setIsNewInstanceDialogOpen] =
    useState<boolean>(false);
  const [creatingHost, setCreatingHost] = useState<string | null>(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!instances) {
      fetchInstances();
    }
  }, [instances]);

  const fetchInstances = () => {
    getAllInstanceSettings()
      .then((instances) => setInstances(instances))
      .catch((error) => console.error("get instances", error));
  };

  const handleCreateNewInstance = (host: string, firstAdminEmail: string) => {
    setCreatingHost(host);
    postNewInstance(host, firstAdminEmail)
      .then((newInstance) => {
        fetchInstances();
        setCreatingHost(null);
      })
      .then(() => {
        enqueueSnackbar("Created new instance: " + host, {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar("Could not create instance: " + host, {
          variant: "error",
        });
      });
  };

  return (
    <Box>
      <NavBar />
      <Box mt={8} display="flex" justifyContent="center">
        <Box p={2} pb={3} pt={6} maxWidth={800} width="100%">
          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Heading>Instances</Heading>
              {instances && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setIsNewInstanceDialogOpen(true)}
                >
                  Add new instance
                </Button>
              )}
            </Box>
            {!instances && <CircularProgress size={64} />}
            {creatingHost && <CreatingInstanceItem hostName={creatingHost} />}
            {instances &&
              instances.map((instance) => (
                <InstanceListItem
                  key={instance.instanceId}
                  instance={instance}
                />
              ))}
          </Box>
        </Box>
      </Box>
      {isNewInstanceDialogOpen && (
        <NewInstanceDialog
          isOpen={isNewInstanceDialogOpen}
          onClose={() => setIsNewInstanceDialogOpen(false)}
          onCreate={handleCreateNewInstance}
        />
      )}
    </Box>
  );
};

export default InstanceListPage;
