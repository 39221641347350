import {
  Box,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Switch,
  Button,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Heading from "../../components/Heading";
import NavBar from "../../components/NavBar";
import {
  getAvailableModules,
  getInstanceSettings,
  updateInstanceSettings,
} from "../../api/api";
import {
  AvailableModules,
  InstanceDetails,
  InstanceLanguage,
  InstanceLanguages,
  Module,
  SubModule,
  UpdateInstanceDetails,
} from "../../types";
import isEqual from "lodash/isEqual";
import { getModuleName } from "../../utils/moduleNames";
import LimitInput from "./LimitsInput";
import { getModuleIcon } from "../../utils/moduleIcons";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: theme.shape.borderRadius,
    },
    moduleItem: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: theme.shape.borderRadius,
    },
    formControl: {
      width: "fit-content",
    },
  })
);

const InstanceDetailPage = () => {
  const [availableModules, setAvailableModules] =
    useState<AvailableModules | null>(null);
  const [instance, setInstance] = useState<InstanceDetails | null>(null);
  const [updateInstance, setUpdateInstance] =
    useState<UpdateInstanceDetails | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (!availableModules) {
      getAvailableModules()
        .then((avM) => setAvailableModules(avM))
        .catch((err) => console.error("get available modules", err));
    }
  }, [availableModules]);

  useEffect(() => {
    if (!instance) {
      fetchInstanceSettings();
    }
  }, [instance]);

  const fetchInstanceSettings = () => {
    const split = window.location.pathname.split("/");
    const instanceId = split[split.length - 1];
    getInstanceSettings(instanceId)
      .then((instance) => {
        if (!instance.enabledSubModules) return;
        setInstance(instance);
        setUpdateInstance(instance);
      })
      .catch((error) => console.error("get instance", error));
  };

  const noChanges = () => {
    if (!instance || !updateInstance) return true;
    return (
      instance.name === updateInstance.name &&
      isEqual(instance.enabledSubModules, updateInstance?.enabledSubModules) &&
      instance.userLimit === updateInstance.userLimit &&
      instance.adminLimit === updateInstance.adminLimit &&
      instance.roomLimit === updateInstance.roomLimit &&
      instance.groupLimit === updateInstance.groupLimit &&
      instance.instanceLanguage === updateInstance.instanceLanguage
    );
  };

  const getIsSubModuleChecked = (
    mainModule: Module,
    subModule: SubModule
  ): boolean => {
    if (!updateInstance) return false;
    for (const [main, subList] of Object.entries(
      updateInstance.enabledSubModules
    )) {
      if (main === mainModule) {
        return subList.includes(subModule);
      }
    }
    return false;
  };

  const handleSubModuleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainModule: Module,
    subModule: string
  ) => {
    if (!updateInstance) return;
    const checked: boolean = (event.target as HTMLInputElement).checked;

    let subModulesList = [...updateInstance.enabledSubModules[mainModule]];
    let newEnabledSubModules: Record<Module, SubModule[]> = {
      ...updateInstance.enabledSubModules,
    };
    if (checked) {
      newEnabledSubModules[mainModule] = [
        ...subModulesList,
        subModule as SubModule,
      ];
    } else {
      newEnabledSubModules[mainModule] = subModulesList.filter(
        (sm: SubModule) => sm !== subModule
      );
    }

    setUpdateInstance({
      ...updateInstance,
      enabledSubModules: newEnabledSubModules,
    });
  };

  const handleChangeUserLimit = (event: any) => {
    if (!updateInstance) return;
    setUpdateInstance({
      ...updateInstance,
      userLimit: event.target.value,
    });
  };

  const handleChangeAdminLimit = (event: any) => {
    if (!updateInstance) return;
    setUpdateInstance({
      ...updateInstance,
      adminLimit: event.target.value,
    });
  };

  const handleChangeRoomLimit = (event: any) => {
    if (!updateInstance) return;
    setUpdateInstance({
      ...updateInstance,
      roomLimit: event.target.value,
    });
  };

  const handleChangeGroupLimit = (event: any) => {
    if (!updateInstance) return;
    setUpdateInstance({
      ...updateInstance,
      groupLimit: event.target.value,
    });
  };

  const handleLanguageChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (!updateInstance) return;
    setUpdateInstance({
      ...updateInstance,
      instanceLanguage: event.target.value as InstanceLanguage,
    });
  };

  const handleSave = () => {
    if (!updateInstance) return;
    updateInstanceSettings(updateInstance).then((response) => {
      fetchInstanceSettings();
      enqueueSnackbar("Your settings have been saved.", { variant: "success" });
    });
  };

  return (
    <Box>
      <NavBar />
      <Box mt={8} display="flex" justifyContent="center">
        <Box p={2} pb={3} pt={6} maxWidth={800} width="100%">
          <Link to="/instances">
            <Box display="flex" alignItems="center" mb={2} mt={1}>
              <ArrowBackIcon />
              <Box mr={1} />
              <Typography>Instances</Typography>
            </Box>
          </Link>
          {!instance && <CircularProgress size={64} />}
          {instance && updateInstance && availableModules && (
            <Box className={classes.container} p={3}>
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Heading>{instance.host}</Heading>
                  <Typography variant="body2">{instance.name}</Typography>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={noChanges()}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>

              <Box mt={4} mb={2}>
                <Typography variant="h6">Enabled modules</Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                {Object.entries(availableModules).map(
                  ([mainModule, subModules]: [string, string[]]) => {
                    if (subModules.length > 0) {
                      return (
                        <Box
                          key={mainModule}
                          mb={3}
                          p={2}
                          className={classes.moduleItem}
                          maxWidth={400}
                        >
                          <Box display="flex" alignItems="center" mb={1}>
                            {getModuleIcon(mainModule)}
                            <Box mr={1} />
                            <Typography variant="subtitle2">
                              {mainModule}
                            </Typography>
                          </Box>
                          <FormGroup>
                            <Box display="flex" flexDirection="column">
                              {subModules &&
                                subModules.map((subModule: string) => (
                                  <FormControlLabel
                                    key={"control-" + subModule}
                                    control={
                                      <Switch
                                        key={"switch-" + subModule}
                                        checked={getIsSubModuleChecked(
                                          mainModule as Module,
                                          subModule as SubModule
                                        )}
                                        onChange={(event) =>
                                          handleSubModuleChange(
                                            event,
                                            mainModule as Module,
                                            subModule
                                          )
                                        }
                                        name={`${subModule}`}
                                        color="primary"
                                      />
                                    }
                                    label={
                                      <Box>
                                        <Typography key={"label-" + subModule}>
                                          {getModuleName(subModule)}
                                        </Typography>
                                      </Box>
                                    }
                                    className={classes.formControl}
                                  />
                                ))}
                            </Box>
                          </FormGroup>
                        </Box>
                      );
                    } else {
                      return <Box key={mainModule} />;
                    }
                  }
                )}
              </Box>

              <Box mt={3} mb={2}>
                <Typography variant="h6">Language</Typography>
              </Box>
              <Select
                value={updateInstance.instanceLanguage}
                onChange={handleLanguageChange}
              >
                {InstanceLanguages.map((lang) => (
                  <MenuItem value={lang}>{lang}</MenuItem>
                ))}
              </Select>

              <Box mt={3} mb={2}>
                <Typography variant="h6">Limits</Typography>
              </Box>
              <LimitInput
                title="User accounts"
                value={updateInstance.userLimit || null}
                unit="accounts"
                onChange={handleChangeUserLimit}
              />
              <LimitInput
                title="Admin accounts"
                value={updateInstance.adminLimit || null}
                unit="accounts"
                onChange={handleChangeAdminLimit}
              />
              <LimitInput
                title="User pages"
                value={updateInstance.roomLimit || null}
                unit="pages"
                onChange={handleChangeRoomLimit}
              />
              <LimitInput
                title="Group pages"
                value={updateInstance.groupLimit || null}
                unit="pages"
                onChange={handleChangeGroupLimit}
              />

              <Box mt={4} mb={2} display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={noChanges()}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default InstanceDetailPage;
