import httpClient from './httpClient';
import { AvailableModules, Instance, InstanceDetails, UpdateInstanceDetails } from '../types';

export const getAllInstanceSettings = async (): Promise<Instance[]> => {
    const response = await httpClient.get(`/`);
    return response.data;
}

export const getInstanceSettings = async (instanceId: string): Promise<InstanceDetails> => {
    const response = await httpClient.get(`/${instanceId}`);
    return response.data;
}

export const postNewInstance = async (host: string, email: string): Promise<InstanceDetails> => {
     const response = await httpClient.post(`/instance`, {
         host,
         email
     });
     return response.data;
}

export const updateInstanceSettings = async (instance: UpdateInstanceDetails): Promise<InstanceDetails> => {
    const response = await httpClient.put(`/`, instance);
    return response.data;
}

export const getAvailableModules = async (): Promise<AvailableModules> => {
    const response = await httpClient.get(`/availablesubmodules`);
    return response.data.availableSubModules;
}
