import { useEffect, useState } from "react";
import "./index.css";

import {
  AuthenticationProvider,
  getUserManager,
  OidcSecure,
  useReactOidc,
} from "@axa-fr/react-oidc-context";
import oidcConfig from "./oidc-config";
import { addAccessToken, setLogoutFunction } from "./api/httpClient";
import Authenticating from "./components/authentication/Authenticating";
import Connected from "./components/authentication/Connected";
import NotAuthenticated from "./components/authentication/NotAuthenticated";
import { CustomEvents } from "@axa-fr/react-oidc-context/dist/oidcContext/AuthenticationContext.hooks";
import AppRoutes from "./routes/AppRoutes";

class LocalStorage {
  constructor() {
    return window.localStorage;
  }
}

const App = () => {
  const [forceLogout, setForceLogout] = useState<boolean>(false);

  const customEvents: CustomEvents = {
    onUserLoaded: () => {},
    onUserUnloaded: () => {},
    onSilentRenewError: () => {},
    onUserSignedOut: () => {
      setForceLogout(true);
    },
    onUserSessionChanged: () => {},
    onAccessTokenExpiring: () => {},
    onAccessTokenExpired: () => {},
  };

  return (
    <AuthenticationProvider
      customEvents={customEvents}
      configuration={oidcConfig}
      authenticating={Authenticating}
      callbackComponentOverride={Connected}
      notAuthenticated={NotAuthenticated}
      UserStore={LocalStorage}
    >
      <OidcSecure>
        <SecureApp forceLogout={forceLogout} />
      </OidcSecure>
    </AuthenticationProvider>
  );
};

export default App;

type SecureAppProps = {
  forceLogout: boolean;
};
const SecureApp = ({ forceLogout }: SecureAppProps) => {
  const [tokenIsSet, setTokenIsSet] = useState<boolean>(false);
  const { events, oidcUser, logout } = useReactOidc();

  useEffect(() => {
    if (forceLogout) {
      const userManager = getUserManager();
      if (userManager) {
        userManager.removeUser();
        window.location.href = window.location.href + "";
      }
    }
  }, [forceLogout]);

  useEffect(() => {
    const userManager = getUserManager();
    const addUserSignedOut = () => {
      userManager.removeUser();
      window.location.href = window.location.href + "";
    };

    if (userManager) {
      userManager.clearStaleState();
      addAccessToken(oidcUser.access_token);
      setTokenIsSet(true);
      setLogoutFunction(logout);
    }

    if (!events) return;
    events.addUserSignedOut(addUserSignedOut);
    return () => {
      events.removeUserSignedOut(addUserSignedOut);
    };
  }, [events, oidcUser, logout]);

  if (!tokenIsSet) {
    return null;
  }

  return <AppRoutes />;
};
